import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This project is a `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.org/docs/themes/what-are-gatsby-themes/"
        }}>{`Gatsby theme`}</a>{` that empowers developers, writers, and designers to create fast, accessible sites that look and feel like Carbon. Follow along to start creating your own site.`}</p>
    </PageDescription>
    <h2>{`First steps`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create your site`}</strong>{` – use the gatsby CLI to bootstrap your site with the starter`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npx gatsby new my-carbon-site https://github.com/carbon-design-system/gatsby-starter-carbon-theme
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Start developing`}</strong>{` – navigate into your directory with `}<inlineCode parentName="p">{`cd my-carbon-site`}</inlineCode>{`.`}</p>
        <p parentName="li">{`Start it up using one of the following snippets. You can tell which command to use based on the lock file at the root of your project (`}<inlineCode parentName="p">{`yarn.lock`}</inlineCode>{` for yarn and `}<inlineCode parentName="p">{`package-lock.json`}</inlineCode>{` for npm). For yarn, type `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{` for npm, you’ll use `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Make some changes!`}</strong>{` – open `}<a parentName="p" {...{
            "href": "//localhost:8000"
          }}>{`localhost:8000`}</a>{` in your browser to see your site running.`}</p>
        <p parentName="li">{`Each of the Items in your side bar correlates to a MDX file in your `}<inlineCode parentName="p">{`src/pages/`}</inlineCode>{` directory. Navigate to a site and try editing the corresponding markdown file. You’ll be able to see it update live!`}</p>
      </li>
    </ol>
    <h2>{`🔍 What’s in here?`}</h2>
    <p>{`Lets check out the structure of our project`}</p>
    <pre><code parentName="pre" {...{}}>{`.
├── LICENSE
├── README.md
├── gatsby-config.js
├── node_modules
├── package.json
├── public
├── src
│   ├── data
│   ├── gatsby-theme-carbon
│   ├── images
│   └── pages
└── yarn.lock
`}</code></pre>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`/node_modules`}</inlineCode></strong>{`: This directory contains all of the modules of code that your project depends on (npm packages) are automatically installed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`/src`}</inlineCode></strong>{`: This directory will contain all of the code related to what you will see on the front-end of your site.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`data`}</strong>{` this is where you’ll update your sidebar order and contents`}</li>
          <li parentName="ul"><strong parentName="li">{`images`}</strong>{` you can colocate your images here or store them next to your pages, whichver you chose`}</li>
          <li parentName="ul"><strong parentName="li">{`gatsby-theme-carbon`}</strong>{` this is where you’ll override (known as shadowing) the default `}<inlineCode parentName="li">{`gatsby-theme-carbon`}</inlineCode>{` components`}</li>
          <li parentName="ul"><strong parentName="li">{`pages`}</strong>{` This is where most of your content will live. You’ll represent each page with an MDX file.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`.gitignore`}</inlineCode></strong>{`: This file tells git which files it should not track / not maintain a version history for.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`gatsby-config.js`}</inlineCode></strong>{`: This is the main configuration file for a Gatsby site. This is where you can specify information about your site (metadata) like the site title and description. You’ll notice that all of the configuration for the site is coming from `}<inlineCode parentName="p">{`gatsby-theme-carbon`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`LICENSE`}</inlineCode></strong>{`: Gatsby is licensed under the Apache 2.0 license.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`yarn.lock`}</inlineCode></strong>{` (See `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` below, first). This is an automatically generated file based on the exact versions of your npm dependencies that were installed for your project. `}<strong parentName="p">{`(You won’t change this file directly).`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`package.json`}</inlineCode></strong>{`: A manifest file for Node.js projects, which includes things like metadata (the project’s name, author, etc). This manifest is how npm knows which packages to install for your project.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      